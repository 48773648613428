<template>
  <div class="notifyDrawer">
    <el-drawer title="消息通知" :class="{'showAni':isAlarm}" :visible.sync="isShowNotify" direction="rtl" :size="400" :modal="false">
      <div class="closeAllBtn tr">
        <el-button type="text" icon="el-icon-delete" class="error" @click="closeAll()">清除所有</el-button>
      </div>
      <div class="list">
        <div class="item" v-for="(item,index) in notifyList" :key="index">
          <div class="closeBtn el-icon-close" @click="closeItem(index)"></div>
          <div class="title mb10">[{{ item.notifyTitle }}]</div>
          <div class="time" v-if="item.notifyType=='deviceAlarm'||item.notifyType=='alarmBehavior'">{{ item.alarmTimeNow}}</div>
          <div v-html="item.content"></div>
          <div class="tr">
            <el-button type="primary" size="small" v-if="item.notifyType=='deviceAlarm'" @click="toAlarmPage(index,item)">处理</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import {formatTime} from '@/utils/utils'
const audioUrl = require('@/assets/js/alarm.wav')
export default {
  name:'',
  data() {
    return {
      isShowNotify: false,
      notifyList:[],
      isAlarm:false,//是否显示动画

      currentAudio:null,//当前播放音频
    }
  },
  //监听vuex值
  watch:{
    '$store.state.wsMsg':function(newVal){
      this.getSkMsg(newVal)
    },
    notifyList:{
      handler:function(newVal){
        this.$emit('notifyNumFun',this.notifyList.length)
      },immediate: true,deep: true
    },
    isShowNotify(newVal){
      if(newVal==false){
        this.playAudio('close')
      }
    }
  },
  created(){
    
  },
  methods:{
    init(){
      let that = this
      this.isShowNotify = true
    },
    //播放音频和css效果
    playAudio(type) {
      let that = this
      if(type){
        that.currentAudio?.pause();
        that.currentAudio = null; // 清除引用
        that.isAlarm = false
        return
      }
      that.isAlarm = true
      // 如果当前有音频正在播放，则停止它  
      if (that.currentAudio) {  
        that.currentAudio.pause();  
        that.currentAudio.currentTime = 0; // 重置音频到开始位置  
        that.currentAudio = null; // 清除当前音频引用  
      }
      // 创建新的Audio实例  
      that.currentAudio = new Audio(audioUrl);  
      that.currentAudio.loop = true;//循环播放
      // 播放音频  
      that.currentAudio.play().then(() => {  
        // 播放成功，设置定时器在10秒后停止（如果还没有新消息来停止它）  
        let tenTimeOut = setTimeout(() => {  
          if (that.currentAudio) {  
            that.currentAudio.pause();
            that.currentAudio = null; // 清除引用  
            that.isAlarm = false
          }  
          clearTimeout(tenTimeOut)
        }, 10000);  
      })  
      .catch(error => {  
        console.error("Error playing audio:", error);  
      }); 
    },
    //websocket接收
    getSkMsg(res) {
      let that = this
      //设备告警通知
      if(res?.type=='deviceAlarm'){
        // this.isAlarm = true
        if (that.timer !== null) {  
          clearTimeout(that.timer);  
          that.timer = null; 
        }
        that.timer = setTimeout(() => {
          that.playAudio()
          clearTimeout(that.timer);
          that.timer = null;  
        }, 200); 
        
        // let timeset = setTimeout(() => {
        //   that.isAlarm = false
        //   clearTimeout(timeset)
        // }, 10000);
        let str;
        str = this.notifyList?.find((li)=>{
          return li.deviceData?.deviceId == res.data.deviceData.deviceId && li.alarmData?.alarmType == res.data.alarmData.alarmType;//筛选出匹配数据
        });
        res.data.alarmTimeNow = formatTime(res.data.alarmData.alarmTime, 'Y-M-D h:m:s')
        if(str){
          for(var i in this.notifyList){
            if(this.notifyList[i].notifyType =='deviceAlarm'&&this.notifyList[i].deviceData.deviceId == res.data.deviceData.deviceId&& this.notifyList[i].alarmData.alarmType == res.data.alarmData.alarmType){
              let obj = res.data
              obj.notifyTitle = '设备告警'
              obj.notifyType = res.type
              obj.notifyNum = this.notifyList[i].notifyNum + 1
              obj.content = `<p class="applyTips">收到<span class="error">${obj.notifyNum}</span>条<span class="error">${res.data.deviceData.homeName}</span>场所下<span class="error">${res.data.deviceData.deviceName}</span>的<span class="error">${res.data.alarmData.alarmTypeName}</span>告警</p>`
              this.notifyList.splice(i,1)
              this.notifyList.unshift(obj)
            }
          }

        }else{
          let obj = res.data
          obj.notifyTitle = '设备告警'
          obj.notifyType = res.type
          obj.notifyNum = 1
          obj.content = `<p class="applyTips">收到<span class="error">${obj.notifyNum}</span>条<span class="error">${res.data.deviceData.homeName}</span>场所下<span class="error">${res.data.deviceData.deviceName}</span>的<span class="error">${res.data.alarmData.alarmTypeName}</span>告警</p>`
          this.notifyList.unshift(obj)
        }
        this.isShowNotify = true
      }
      //行为告警通知
      if(res?.type=='alarmBehavior'){
        let str;
        str = this.notifyList?.find((li)=>{
          return li.deviceId && li.deviceId == res.data.deviceId && li.alarmBehaviorType == res.data.alarmBehaviorType;//筛选出匹配数据
        });
        res.data.alarmTimeNow = formatTime(res.data.alarmTime, 'Y-M-D h:m:s')
        if(str){
          for(var i in this.notifyList){
            if(this.notifyList[i].notifyType =='alarmBehavior'&&this.notifyList[i].deviceId == res.data.deviceId&& this.notifyList[i].alarmBehaviorType == res.data.alarmBehaviorType){
              let obj = res.data
              obj.notifyTitle = '行为告警'
              obj.notifyType = res.type
              obj.notifyNum = this.notifyList[i].notifyNum + 1
              obj.content = `<p class="applyTips">收到<span class="error">${obj.notifyNum}</span>条<span class="error">${res.data.homeName}</span>场所下<span class="error">${res.data.deviceName}</span>的<span class="error">${res.data.alarmBehaviorTypeName}</span>告警</p>`
              this.notifyList.splice(i,1)
              this.notifyList.unshift(obj)
            }
          }
        }else{
          let obj = res.data
          obj.notifyTitle = '行为告警'
          obj.notifyType = res.type
          obj.notifyNum = 1
          obj.content = `<p class="applyTips">收到<span class="error">${obj.notifyNum}</span>条<span class="error">${res.data.homeName}</span>场所下<span class="error">${res.data.deviceName}</span>的<span class="error">${res.data.alarmBehaviorTypeName}</span>告警</p>`
          this.notifyList.unshift(obj)
        }
        this.isShowNotify = true
      }
      //设备状态通知
      if(res?.type=='deviceStatus'){
        let obj = res.data
        obj.notifyTitle = '设备状态'
        obj.notifyType = res.type
        obj.notifyNum = 1
        obj.content = `<p class="applyTips"><span class="error">${res.data.deviceData.homeName}</span>场所下的<span class="error">${res.data.deviceData.deviceName}</span>设备<span class="error">${res.data.deviceData.deviceStatus=='1'?'已上线':'已离线'}</span></p>`
        this.notifyList.unshift(obj)
        this.isShowNotify = true
      }
      //设备操作结果通知
      if(res?.type=='deviceOperation'){
        let obj = res.data
        obj.notifyTitle = '设备操作结果'
        obj.notifyType = res.type
        obj.notifyNum = 1
        obj.content = `<p class="applyTips"><span class="error">${res.data.deviceData.homeName}</span>场所下的<span class="error">${res.data.deviceData.deviceName}</span>设备<span class="error">${res.data.operResult.content}</span></p>`
        this.notifyList.unshift(obj)
        this.isShowNotify = true
      }
      //节能策略提醒（一周一次）
      if(res?.type=='energyStrategy'){
        let obj = res.data
        obj.notifyTitle = '节能策略提醒'
        obj.notifyType = res.type
        obj.notifyNum = 1
        obj.content = `<p class="applyTips"><span class="error">${res.data.deviceData.homeName}</span>场所下的<span class="error">${res.data.deviceData.deviceName}</span>设备<span class="error">${res.data.deviceData.extra}</span></p>`
        this.notifyList.unshift(obj)
        this.isShowNotify = true
      }
    },
    //跳转到告警管理页面
    async toAlarmPage(index,item){
      // this.$router.push({
      //   path:'/safety-alarm-logs',
      //   query:{
      //     'homeId':item.deviceData.homeId,
      //     'alarmStatus':item.alarmData.alarmStatus,
      //     'alarmType':item.alarmData.alarmType
      //   }
      // })
      // this.closeItem(index)
      let dataObj = {
        'id':item.alarmData.id,
        'alarmStatus':1
      }
      const res = await apiUrl.alarmHandle(dataObj)
      if(res?.code==200){
        this.$message.success('处理成功')
        this.closeItem(index)
      }else{
        this.$message.error(res.message)
      }
    },
    //关闭所有
    closeAll(){
      this.notifyList = []
    },
    //关闭单个
    closeItem(index){
      this.notifyList.splice(index,1)
    }
  }
}
</script>
<style lang='scss'>
@keyframes aniShadow {  
  0%, 100% {
    box-shadow: 0 50px 200px 100px rgba(#F56C6C,.8) inset;
  }  
  50% { 
    box-shadow: 0 50px 200px 100px rgba(#F56C6C,0.1) inset;
  }  
}
.notifyDrawer{
  text-align: left;
  position: relative;
  z-index: 1000 !important;
  .el-drawer__wrapper{
    &.showAni{
      animation: aniShadow 1s infinite;
    }
  }
  .el-drawer{
    background-color: #031e44;
    box-shadow: 0 8px 10px 20px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12);
  }
  .el-drawer__header{
    padding: 10px 20px 10px;
    border-bottom: 1px solid #000;
    background: linear-gradient(to right, #006ae1, #16b7ff);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
  .closeAllBtn{
    border-bottom: 1px solid #2c3c58;
    padding: 0 10px;
    .el-button--text{
      &:hover{
        color: #F56C6C;
      }
    }
  }
  .list{
    padding: 0 10px;
    height: calc(100vh - 87px);
    overflow: auto;
    .item{
      border-bottom: 1px solid #2c3c58;
      padding: 10px 0;
      color: #fff;
      position: relative;
      .closeBtn{
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 16px;
        padding: 5px;
        cursor: pointer;
        &:hover{
          color: #F56C6C;
        }
      }
      .title{
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
      }
      .time{
        font-size: 12px;
        color: #999;
      }
      .applyTips{
        padding-bottom: 10px;
      }
    }
  }
}
</style>