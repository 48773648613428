import Vue from "vue";
import Vuex from "vuex";
import VueCookie from "vue-cookie";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    wsMsg:{},//websocket返回数据
    token:VueCookie.get('token'),
    authLicence:false,//平台授权
    changeAreaNum:0,//改变区域
    csType:'',//当前区域
    zoneData:[],//区域数据
    homeId:null,//当前场所
    homeList:[],//场所列表
    appShowName:'',//应用名称
  },
  mutations: {
    // websocket返回数据
    wsMsg(state, value) {
      state.wsMsg = value
    },
    token(state,value) {
      state.token = value
    },
    authLicence(state,value) {
      state.authLicence = value
    },
    csType(state,value) {
      state.csType = value
    },
    changeAreaNum(state,value) {
      state.changeAreaNum = value
    },
    zoneData(state,value) {
      state.zoneData = value
    },
    homeId(state,value) {
      state.homeId = value
    },
    homeList(state,value) {
      state.homeList = value
    },
    appShowName(state,value) {
      state.appShowName = value
    },
  },
})
