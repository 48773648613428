<template>
  <div class="changePw">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDiv">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="原密码:" prop="oldPwd">
            <el-input v-model="inform.oldPwd" type="password" placeholder="请输入原密码" autocomplete="new-password"></el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="newPwd">
            <el-input v-model="inform.newPwd" type="password" placeholder="请输入新密码" autocomplete="new-password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="confirmPwd">
            <el-input v-model="inform.confirmPwd" type="password" placeholder="请输入确认" autocomplete="new-password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
var vm;
export default {
  name:'changePw',
  data() {
    //验证form格式
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('确认密码不能为空'))
      } else if (value !== this.inform.newPwd) {
        callback(new Error("确认密码与密码不一致"))
      } else {
        callback()
      }
    }
    return {
      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      inform:{
        oldPwd:'',//原密码
        newPwd:'',//新密码
        confirmPwd:'',//确认密码
      },
      rules :{
        oldPwd: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          // { min:4, message:'长度不能低于4位', trigger:'blur'},
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          //{ min:8, max:20, message:'长度不能低于8-20位', trigger:'blur'},
          // { pattern : /^[A-Za-z0-9]+$/g, message:'请输入字母、数字格式的字符'},
          { pattern : '^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[!@#$%^&*]).{8,20}$', message:'密码需包含字母、数字、特殊字符(!@#$%^&*)，长度8-20个字符'}
        ],
        confirmPwd: [
          { required: true, validator: validatePass, trigger: "blur" }
        ],
      },
    }
  },
  created(){
    vm = this
  },
  methods:{
    //初始化
    init (){
      vm.dioWidth = '500px'
      vm.dioTit = '修改密码'
      vm.isDio = true
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          vm.userUpdatePwd()
        } else {return false}
      })
    },
    //修改密码
    async userUpdatePwd(){
      let dataObj = {
        'oldPwd':vm.$md5(vm.inform.oldPwd),//旧密码
        'newPwd':vm.$md5(vm.inform.newPwd),//新密码
      }
      const res = await apiUrl.userUpdatePwd(qs.stringify(dataObj))
      if(res?.code==200){
        vm.$message.success('修改成功')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.isDio = false
    },
  }
}
</script>
<style lang="scss">
.changePw{
  .el-dialog__header{
    text-align: left;
  }
}
</style>