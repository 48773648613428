import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            isNoHead: true,//是否不显示头部
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')//登录
    },
    {
        path: '/index',
        name: 'index',
        meta: {
            requireAuth: true,//是否需要登录才能访问该页面
            isIndependent: true,//是否独立的
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')//首页
    },
    {
        path: '/monitoring',
        name: 'monitoring',
        meta: {
            title: '监控',
            requireAuth: true,//是否需要登录才能访问该页面
            isIndependent: true,//是否独立的
            keepAlive: true,
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/monitoring/index.vue')//监控
    },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        meta: {
            title: '个人中心',
            requireAuth: true,//是否需要登录才能访问该页面
            isIndependent: true,//是否独立的
        },
        component: () => import( '../views/personalCenter.vue')//平台配置-个人中心
    },
    {
        path: '/system-department',
        name: 'system-department',
        meta: {
            title: '组织管理',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/system/config/department/index.vue')//组织管理
    },
    {
        path: '/system-role',
        name: 'system-role',
        meta: {
            title: '角色管理',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/system/config/role/index.vue')//角色管理
    },
    {
        path: '/system-user',
        name: 'system-user',
        meta: {
            title: '用户管理',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/system/config/user/index.vue')//用户管理
    },
    {
        path: '/system-person',
        name: 'system-person',
        meta: {
            title: '人员管理',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/system/config/person/index.vue')//人员管理
    },
    {
        path: '/system-menu',
        name: 'system-menu',
        meta: {
            title: '菜单管理',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/system/config/menu/index.vue')//菜单管理
    },
    {
        path: '/platform-parameter',
        name: 'platform-parameter',
        meta: {
            title: '参数配置',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/system/config/platform/parameter/index.vue')//平台配置-参数配置
    },
    {
        path: '/platform-platformInfo',
        name: 'platform-platformInfo',
        meta: {
            title: '平台信息',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/system/config/platform/platformInfo/index.vue')//平台配置-平台信息
    },
    {
        path: '/safety-area',
        name: 'safety-area',
        meta: {
            title: '区域管理',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/safety/area/index.vue')//安全管理-区域管理
    },
    {
        path: '/safety-place',
        name: 'safety-place',
        meta: {
            title: '场所管理',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/safety/place/index.vue')//安全管理-场所管理
    },
    {
        path: '/safety-device',
        name: 'safety-device',
        meta: {
            title: '设备信息',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/device/index.vue')//安全管理-设备管理
    },
    {
        path: '/safety-device-gateway',
        name: 'safety-device-gateway',
        meta: {
            title: '配电网关',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/device/gateway/index.vue')//安全管理-设备管理
    },
    {
        path: '/safety-device-fourserial',
        name: 'safety-device-fourserial',
        meta: {
            title: '串口服务器',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/device/fourserial/index.vue')//安全管理-设备管理
    },
    {
        path: '/safety-device-io',
        name: 'safety-device-io',
        meta: {
            title: 'IO采集器',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/device/IO/index.vue')//安全管理-设备管理
    },
    {
        path: '/safety-device-box',
        name: 'safety-device-box',
        meta: {
            title: '边缘计算',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/device/devicebox/index.vue')//安全管理-设备管理
    },
    // {
    //   path: '/safety-device-electric',
    //   name: 'safety-device-electric',
    //   meta:{
    //     title:'电气设备',
    //     requireAuth:true,//是否需要登录才能访问该页面
    //   },
    //   component: () => import( '../views/safety/device/electric/index.vue')//安全管理-设备管理-电气设备
    // },
    // {
    //   path: '/safety-device-protection',
    //   name: 'safety-device-protection',
    //   meta:{
    //     title:'安防设备',
    //     requireAuth:true,//是否需要登录才能访问该页面
    //   },
    //   component: () => import( '../views/safety/device/protection/index.vue')//安全管理-设备管理-安防设备
    // },
    // {
    //   path: '/safety-device-vidicon',
    //   name: 'safety-device-vidicon',
    //   meta:{
    //     title:'监控设备',
    //     requireAuth:true,//是否需要登录才能访问该页面
    //   },
    //   component: () => import( '../views/safety/device/vidicon/index.vue')//安全管理-设备管理-监控设备
    // },
    // {
    //   path: '/safety-device-sensor',
    //   name: 'safety-device-sensor',
    //   meta:{
    //     title:'传感设备',
    //     requireAuth:true,//是否需要登录才能访问该页面
    //   },
    //   component: () => import( '../views/safety/device/sensor/index.vue')//安全管理-设备管理-传感设备
    // },
    {
        path: '/safety-alarm-logs',
        name: 'safety-alarm-logs',
        meta: {
            title: '告警管理',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/alarm/logs/index.vue')//安全管理-告警管理-告警管理
    },
    {
        path: '/safety-alarm-historylogs',
        name: 'safety-alarm-historylogs',
        meta: {
            title: '告警历史',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/alarm/historylogs/index.vue')//安全管理-告警管理-告警历史
    },
    {
        path: '/safety-alarm-behavior',
        name: 'safety-alarm-behavior',
        meta: {
            title: '行为告警',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/alarm/behavior/index.vue')//安全管理-告警管理-行为告警
    },
    {
        path: '/safety-alarm-devicebox',
        name: 'safety-alarm-devicebox',
        meta: {
            title: '边缘计算记录',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/alarm/devicebox/index.vue')//安全管理-告警管理-边缘计算记录
    },
    {
        path: '/safety-attendance-config',
        name: 'safety-attendance-config',
        meta: {
            title: '考勤配置',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/attendance/config/index.vue')//安全管理-考勤管理-考勤配置
    },
    {
        path: '/safety-attendance-data',
        name: 'safety-attendance-data',
        meta: {
            title: '考勤数据',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/attendance/data/index.vue')//安全管理-考勤管理-考勤数据
    },
    {
        path: '/safety-statistics',
        name: 'safety-statistics',
        meta: {
            title: '数据统计',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/statistics/index.vue')//安全管理-数据统计
    },
    {
        path: '/safety-circuit',
        name: 'safety-circuit',
        meta: {
            title: '电气线路',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/circuit/index.vue')//安全管理-电气线路
    },
    {
        path: '/system-ticket',
        name: 'system-ticket',
        meta: {
            title: '工单管理',
            requireAuth: true,//是否需要登录才能访问该页面
        },
        component: () => import( '../views/system/config/ticket/index.vue')//工单管理
    },
    {
        path: '/safety-scene',
        name: 'safety-scene',
        meta: {
            title: '场景配置',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/scene/index.vue')//安全管理-场景配置
    },
    {
        path: '/safety-electricityReport',
        name: 'safety-electricityReport',
        meta: {
            title: '用电报告',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/electricityReport/index.vue')//安全管理-用电报告
    },
    {
        path: '/safety-inspect-task',
        name: 'safety-inspect-task',
        meta: {
            title: '巡检任务',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/inspect/task/index.vue')//安全管理-巡检任务
    },
    {
        path: '/safety-inspect-record',
        name: 'safety-inspect-record',
        meta: {
            title: '巡检记录',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/inspect/record/index.vue')//安全管理-巡检记录
    },
    {
        path: '/safety-asset-classify',
        name: 'safety-asset-classify',
        meta: {
            title: '资产分类',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/assetManage/classify/index.vue')//安全管理-资产管理-资产分类
    },
    {
        path: '/safety-asset-statistics',
        name: 'safety-asset-statistics',
        meta: {
            title: '资产统计',
            requireAuth: true,//是否需要登录才能访问该页面
            isShowCS: true,//是否显示场所
        },
        component: () => import( '../views/safety/assetManage/statistics/index.vue')//安全管理-资产管理-资产统计
    },
]

let originPush = VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {    //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    } else {                     //如果没有传回调函数，手动添加
        originPush.call(this, location, () => {
        }, () => {
        });
    }
}

const router = new VueRouter({
    mode: 'history', // 访问路径不带#号
    // base: '', // 配置单页应用的基路径
    routes,
});

export default router;
