
import axios from '../../axios/index'
import VueCookie from "vue-cookie";

var mp3=null;
const vtp = {
  //永久存储
  set (key, data) {
    localStorage.setItem(key, JSON.stringify(data))
  },
  //获取永久存储
  get (key) {
    return JSON.parse(localStorage.getItem(key))
  },
  //删除永久存储
  rem (key) {
    localStorage.removeItem(key)
  },
  //临时存储
  setSession (key, data) {
    sessionStorage.setItem(key, JSON.stringify(data))
  },
  //获取临时存储
  getSession (key) {
    return JSON.parse(sessionStorage.getItem(key))
  },
  //返回计算后高度
  winHei (o = 0) {
    return document.documentElement.clientHeight - o
  },
  //返回计算后宽度
  winWid (o = 0) {
    return document.documentElement.clientWidth - o
  },
  fildata (data, key, hndn = 'selNavJson', reNum) {
    var item = data
    for (var i = 0; i < data.length; i++) {
      if (item[i].path === key) {
        vtp.set(hndn, item[i])
        reNum = i
      } else {
        if (item[i].children !== undefined) { vtp.fildata(item[i].children, key) }
      }
    }
    return [vtp.get(hndn), hndn, reNum]
        // vtp.get('selNavJson')
  },
  str (o) {
    return JSON.stringify(o)
  },
  par (o) {
    return JSON.parse(o)
  },
  getAfterStr (o, s, isb = 0, ifs = 1) {
    o = ifs ? o[isb] : o
    return s ? o.substring(o.lastIndexOf('/')) : o.substring(o.lastIndexOf('/')).split('/')[1]
  },
  getBeforeStr (o) {
    return o.substring(0, o.lastIndexOf('/'))
  },
  returnRootPathStr (o) {
    let d = vtp.getBeforeStr(o)
    if (d === '') { d = o }
    return d
  },
  //返回数组的第二个元素
  removeStr (o) {
    return o.split('/')[1]
  },
  //跳转路由
  toRouter(o,src,opn = null){
    opn === null ? o.$router.push(src) : o.$router.push({ path: src, query: opn});
  },
  //返回字符串长度乘以size的数
  strToNum(o,size = 21){
    return o.toString().length * size;
  },
  //返回触发事件的节点
  reThis(o){
    return o.currentTarget;
  },
  reData(o,i){
    o.splice(i, 1);
  },
  //返回与当前时间相差的天、时、分、秒数据
  timeValFun(d1,d2){
    if(d1){//di作为一个变量传进来
        //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
        var dateBegin = new Date(d1.replace(/-/g, "/"));//将-转化为/，使用new Date

        var dateEnd;
        if(d2){
          dateEnd = new Date(d2);//将-转化为/，使用new Date
        }else{
          dateEnd = new Date();//获取当前时间
        }
        var dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差的毫秒数
        var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
        var leave1=dateDiff%(24*3600*1000) //计算天数后剩余的毫秒数
        var hours=Math.floor(leave1/(3600*1000))//计算出小时数
        //计算相差分钟数
        var leave2=leave1%(3600*1000) //计算小时数后剩余的毫秒数
        var minutes=Math.floor(leave2/(60*1000))//计算相差分钟数
        //计算相差秒数
        var leave3=leave2%(60*1000) //计算分钟数后剩余的毫秒数
        var seconds=Math.round(leave3/1000)
        // console.log(" 相差 "+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒")
        // console.log(dateDiff+"时间差的毫秒数",dayDiff+"计算出相差天数",leave1+"计算天数后剩余的毫秒数" ,hours+"计算出小时数",minutes+"计算相差分钟数",seconds+"计算相差秒数");
        return {'day':dayDiff,'hours':hours,'minutes':minutes,'seconds':seconds}
    }
    
  },
  //少于10在前面添加零
  addZero (num) {
    if (parseInt(num)>=0 && parseInt(num) < 10) {
      num = '0' + num
    }
    if(parseInt(num)<0){
      num = '00'
    }
    return num
  },
    dataDicVal(v,k,fun = () => {} ){
        if(v){
            if(Array.isArray(v)){
                v.map((item1,i) => {
                    axios.get(axios.dicUrl+k).then(data => { 
                        if(data){
                            let info = data.filter(item => { return item.dicItemCode == item1 ? true : false });
                            v[i] = info[0].dicItemName;
                            if(i == (v.length-1)){fun(v,data);}
                        }else{
                            fun('未匹配字段')
                        }
                    })
                });
            }else{
                axios.get(axios.dicUrl+k).then(data => { 
                    if(data){
                        let info = data.filter(item => { return item.dicItemCode == v ? true : false });
                        info.length > 0 ? fun(info[0],data) :fun(v,data) ;
                    }else{
                        fun('未匹配字段')
                    }
                })
            }
        }
    
    },
    winSize(fun = () => {}){
        window.onresize = () => {
            return (() => {
                fun(this.winHei());
            })()
        }
    },
    //接口调用成功验证
    updataState(o,t,fun = ()=>{}){
        if (o && o.code === 0) {
            t.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500
            })
            fun();
        } else {
            t.$message.error(o.msg)
        }
        
    },
    
    //打印日志
    conLog(key,value){
      return console.log(key ? key :'',value ? value:'')
      // return key+value
    },

    //清除cookie数据
    clearLoginInfo () {
      VueCookie.delete('token')
      vtp.rem('userInfo')
      // vtp.rem('platInfo')
      vtp.rem('isEncrypt')
      vtp.setSession('headActive','/index')
    },

    //标准时间转字符串时间格式
    time(date) {
      date = new Date(date)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var second = date.getSeconds()
      second = second < 10 ? '0' + second : second
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
    },

    //相似度
    similar(s, t, f) {
      if (!s || !t) {
        return 0
      }
      var l = s.length > t.length ? s.length : t.length
      var n = s.length
      var m = t.length
      var d = []
      f = f || 3
      var min = function(a, b, c) {
        return a < b ? (a < c ? a : c) : (b < c ? b : c)
      }
      var i, j, si, tj, cost
      if (n === 0) return m
      if (m === 0) return n
      for (i = 0; i <= n; i++) {
        d[i] = []
        d[i][0] = i
      }
      for (j = 0; j <= m; j++) {
        d[0][j] = j
      }
      for (i = 1; i <= n; i++) {
        si = s.charAt(i - 1)
        for (j = 1; j <= m; j++) {
          tj = t.charAt(j - 1)
          if (si === tj) {
            cost = 0
          } else {
            cost = 1
          }
          d[i][j] = min(d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost)
        }
      }
      let res = (1 - d[n][m] / l)
      return res.toFixed(f)
    },

  /** 
  * 时间戳转化为年 月 日 时 分 秒 
  * number: 传入时间戳 
  * format：返回格式，支持自定义，但参数必须与formateArr里保持一致 
  */
  formatNumber(n) {
    n = n.toString();
    return n[1] ? n : "0" + n;
  },
  formatTime(number, format) {
    var formateArr = ["Y", "M", "D", "h", "m", "s"];
    var returnArr = [];
    var date = new Date(number);
    returnArr.push(date.getFullYear());
    returnArr.push(this.formatNumber(date.getMonth() + 1));
    returnArr.push(this.formatNumber(date.getDate()));
    returnArr.push(this.formatNumber(date.getHours()));
    returnArr.push(this.formatNumber(date.getMinutes()));
    returnArr.push(this.formatNumber(date.getSeconds()));
    for (var i in returnArr) {
      format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
  },

  /**
   * 时间格式截取时分秒
  */
  getHms(str){
    var index=str.lastIndexOf("\ ");//获取空格出现的位置
    return str.substring(index+1,str.length);
  },
  /**
   * 播放mp3文件
  */
  audioMp3(url){
    if(mp3){
      mp3.pause();//如果有语音，就把旧的停掉
    }
    mp3 = new Audio(url);
    mp3.play();
  },

  /**
   * 通过数组的localCode排序
  */
  sortData(a, b) {
    return parseInt(a.id) - parseInt(b.id)
  },
  
  /**遍历查找指定参数的树形节点
   * arr:遍历的树形数组
   * children:下级节点的数组名称
   * val:需要判断的参数
   * parems:被判断的节点属性
  */
  
  findItem(arr2,arr, children='children',val, params='url'){
    //利用foreach循环遍历
    arr.forEach((item) => {
      let str = item[params]
      //判断递归结束条件
      if(str!=null && str.indexOf(val) != -1 ){
        // 返回当前节点
        arr2.push(item)
      }else if(item[children] != null){//判断chlidren是否有数据
        //递归调用
        vtp.findItem(arr2,item[children], children, val, params);                 
      }                   
    })
    return arr2;
  }

}

export default vtp
