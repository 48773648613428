import axios from '@/axios/index'
import VueCookie from "vue-cookie";
/**
 * 获取uuid
 */
 export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}
/**
 * 生成指定位数的唯一值
 * len:长度
 * radix:基数
*/
export function uuid(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [], i;
  radix = radix || chars.length;
 
  if (len) {
   // Compact form
   for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
  } else {
   // rfc4122, version 4 form
   var r;
 
   // rfc4122 requires these characters
   uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
   uuid[14] = '4';
 
   // Fill in random data. At i==19 set the high bits of clock sequence as
   // per rfc4122, sec. 4.1.5
   for (i = 0; i < 36; i++) {
    if (!uuid[i]) {
     r = 0 | Math.random()*16;
     uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
    }
   }
  }
 
  return uuid.join('');
}
/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'parentId',children='children') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]][children]) {
        temp[data[k][pid]][children] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]][children].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

// 根据字典类型查询字典数据信息
// export function getDictsReq(dictType) {
//   return axios({
//     url: `${globalStr}/dict/listByDictDomains`,
//     data:dictType,
//     method: 'post',
//     headers:{
//       'DYNAMIC-DUTY-AUTH-TOKEN':VueCookie.get("token")
//     }
//   })
// }
// export async function getDicts(dictType){
//   // var data = sessionStorage.getItem("dict_" + dictType);
//   var data = null
//   if(!data){
//     data = await apiUrl.getDictsReq(dictType)
//   }else{
//     data = JSON.parse(data);
//   }
//   return new Promise(function(resolve){//做一些异步操作
//     resolve(data);
//   });
// }

// 回显数据字典
export function selectDictLabel(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].dicItemCode == ('' + value)) {
			actions.push(datas[key].dicItemName);
			return true;
		}
	})
	return actions.join('');
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
	var actions = [];
	var currentSeparator = undefined === separator ? "," : separator;
	var temp = value.split(currentSeparator);
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].dicItemCode == ('' + temp[val])) {
				actions.push(datas[key].dicItemName + currentSeparator);
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1);
}


//获取本地ip地址
export function getYourIP(){
  var RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
  if (RTCPeerConnection) (function () {
    var rtc = new RTCPeerConnection({iceServers:[]});
    if (1 || window.mozRTCPeerConnection) {     
      rtc.createDataChannel('', {reliable:false});
    };     
    rtc.onicecandidate = function (evt) {
      if (evt.candidate) grepSDP("a="+evt.candidate.candidate);
    };
    rtc.createOffer(function (offerDesc) {
      grepSDP(offerDesc.sdp);
      rtc.setLocalDescription(offerDesc);
    }, function (e) { console.warn("offer failed", e); });
    var addrs = Object.create(null);
    addrs["0.0.0.0"] = false;
    function updateDisplay(newAddr) {
      if (newAddr in addrs) return;
      else addrs[newAddr] = true;
      var displayAddrs = Object.keys(addrs).filter(function (k) { return addrs[k]; });
      for(var i = 0; i < displayAddrs.length; i++){
        if(displayAddrs[i].length > 16){
          displayAddrs.splice(i, 1);
          i--;
        }
      }
      alert(displayAddrs[0])
      // console.log(displayAddrs[0])
    }
            
    function grepSDP(sdp) {
      var hosts = [];
      sdp.split('\r\n').forEach(function (line, index, arr) {
        if (~line.indexOf("a=candidate")) {    
          var parts = line.split(' '),       
              addr = parts[4],
              type = parts[7];
          if (type === 'host') updateDisplay(addr);
        } else if (~line.indexOf("c=")) {       
          var parts = line.split(' '),addr = parts[2];
          updateDisplay(addr);
        }
      });
    }
  })();
}

/** 

* 时间戳转化为年 月 日 时 分 秒 

* number: 传入时间戳 

* format：返回格式，支持自定义，但参数必须与formateArr里保持一致 

*/
function formatNumber(n) {
  n = n.toString();

  return n[1] ? n : "0" + n;
}
export function formatTime(number, format) {
  var formateArr = ["Y", "M", "D", "h", "m", "s"];

  var returnArr = [];

  var date = new Date(number);

  returnArr.push(date.getFullYear());

  returnArr.push(formatNumber(date.getMonth() + 1));

  returnArr.push(formatNumber(date.getDate()));

  returnArr.push(formatNumber(date.getHours()));

  returnArr.push(formatNumber(date.getMinutes()));

  returnArr.push(formatNumber(date.getSeconds()));

  for (var i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }

  return format;
}
/** 
 * 当前直接转换为当前，昨天，以往时间 （针对于聊天室）
* 时间戳转化为年 月 日 时 分 秒 
* e: 传入时间戳 
*/
export function dateTime(e) {
  let old = new Date(e);
  let now = new Date();
  //获取old具体时间
  let d = old.getTime();
  let h = old.getHours();
  let m = old.getMinutes();
  let Y = old.getFullYear();
  let M = old.getMonth() + 1;
  let D = old.getDate();
  //获取now具体时间
  let nd = now.getTime();
  let nh = now.getHours();
  let n = now.getMinutes();
  let nY = now.getFullYear();
  let nM = now.getMonth() + 1;
  let nD = now.getDate();

  //当天的时间
  if (D === nD && M === nM && Y === nY) {
    if (h < 10) {
      h = '0' + h;
    }
    if (m < 10) {
      m = '0' + m;
    }
    return h + ':' + m;
  }
  //昨天时间
  if (D + 1 === nD && M === nM && Y === nY) {
    if (h < 10) {
      h = '0' + h;
    }
    if (m < 10) {
      m = '0' + m;
    }
    return '昨天 ' + h + ':' + m;
  } else if (Y == nY) {
    //今年
    if (h < 10) {
      h = '0' + h;
    }
    if (m < 10) {
      m = '0' + m;
    }
    return M + '月' + D + '日 ' + h + ':' + m
  } else {
    //大于今年
    if (h < 10) {
      h = '0' + h;
    }
    if (m < 10) {
      m = '0' + m;
    }
    return Y + '年' + M + '月' + D + '日 ' + h + ':' + m
  }
}

/**
 * 身份证号码
 * @param {*} s
 */
export function isIDCard (s) {
  return /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/.test(s)
}

//格式化时间
export function num(n){
  return n < 10 ?'0'+n :n;
}
/**
 * 获取到开始时间的minNum分钟后的时间
 * timeNum:开始时间的时间戳
 * minNum:多少分钟
*/
export function setTime (timeNum,minNum) {
  // let t = new Date().getTime() + timeNum * 60 * 1000;   //40分钟 * 60秒 * 1000
  let t = timeNum + minNum * 60 * 1000
  let d = new Date(t);
  let theMonth = num(d.getMonth() + 1);//也可以使用(d.getMonth() + 1).padStart(2, '0')
  let theDate = num(d.getDate());
  let theHours = num(d.getHours());
  let theMinutes = num(d.getMinutes());
  let theSeconds = num(d.getSeconds());
  let date = d.getFullYear() + '-' + theMonth + '-' + theDate
  let time = theHours + ':' + theMinutes +':'+theSeconds
  let Spare = date + ' ' + time
  return Spare
}

/**
 * 倒计时函数
 * time:时间格式参数2021-03-05 10:02:01
*/
export function countDownTime(time){
  var startTime = new Date();//当前时间
  var endTime =  new Date(time);//结束时间
  var countDown = (endTime.getTime()-startTime.getTime());//算出中间差，以毫秒数返回
  var t;
  if(countDown>0){
    // countDown = Math.ceil(countDown / 1000)
    // var oMinute = Math.floor(((countDown % (24 * 60 * 60)) % (60 * 60)) / 60);//获取分钟数
    // var oSecond = Math.floor(((countDown % (24 * 60 * 60)) % (60 * 60)) % 60);//获取秒数
    var oMinute = parseInt(countDown/1000/60);//获取分钟数
    var oSecond = parseInt(countDown/1000%60);//获取秒数
    //输出时间
    t = {
      minute:num(oMinute),
      second:num(oSecond),
    }
  }else{
    t = {
      minute:'00',
      second:'00'
    }
  }
  return t
}

/**
 * 截取字符串指定字符：横杠的前、后数据
 * obj:需要截取的字符串
 * state:0截取前面，1截取后面
*/
export function getCaption(obj,state) {
  var index=obj.lastIndexOf("\-");
  if(state==0){
  obj=obj.substring(0,index);
  }else {
  obj=obj.substring(index+1,obj.length);
  }
  return obj;
}


/**
 * 是否有权限
 * @param {*} key
 * 所有key:(去查看菜单管理的菜单编码)
 * 
 */
 export function isAuth (key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 获取所有按钮权限
 * @param {*} data 菜单数据
 */
let authArr = []
export function getAuthMenu(data){
  // console.log(data);
  if(data.length>0){
    for(var i in data){
      if(data[i].menuType=='BUTTON'){
        authArr.push(data[i].menuCode)
      }
      if(data[i].childPageMenus?.length>0){
        getAuthMenu(data[i].childPageMenus)
      }
    }
  }
  return authArr
}
/**
 * 递归返回符合条件的元素
 * @param {*} data 树形数据
 * @param {*} tag 需要判断的字段名
 */
export function findResult (data, tag) {
  let result = {}
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    if (element.children && element.children.length > 0) {
      result = findResult(element.children, tag)
    } else {
      if (element.equBaseId == tag) {
        result = element
      }
    }
  }
  return result
}
/**
 * 递归返回符合条件的元素
 * @param {*} data 树形数据
 * @param {*} tag 需要判断的字段名
 */
export function findResult3D (data, tag,type) {
  let result = {}
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    let children = type=='1' ? element.attributes.children : element.children
    let equBaseId = type=='1' ? element.attributes.equBaseId : element.equBaseId
    if (children && children.length > 0) {
      result = findResult3D(children, tag,'2')
    } else {
      if (equBaseId == tag) {
        result = element
      }
    }
  }
  return result
}
/**
 * 递归过滤删除没有子节点的属性名
 * @param {*} data 树形数据
 */
export function delChildren(data){
  for(var i in data){
    if(data[i].childDepartments.length == 0){
      delete data[i].childDepartments
    }else{
      delChildren(data[i].childDepartments)
    }
  }
}
/**
 * 递归过滤删除没有子节点的属性名
 * @param {*} data 树形数据
 */
export function delChildren2(data,children){
  for(var i in data){
    // console.log(data[i][children]);
    if(data[i][children].length == 0){
      delete data[i][children]
    }else{
      delChildren2(data[i][children],children)
    }
  }
}
/**
 * 递归过滤菜单有没有与当前路由相同的子节点
 * @param {*} rouUrl 当前路由
 * @param {*} data 树形数据
 */
export function isAuthMenu(rouUrl,data,result){
  if(data.length>0){
    for (let item of data) {
      if (item.menuUrl === rouUrl) {
        //将查找到的目标数据加入结果数组中
        result.unshift(item.id)
        return true
      }
      if (item.childPageMenus?.length > 0 && item.buttonMenus.length==0) {
        //根据查找到的结果往上找父级节点
        let isFind = isAuthMenu( rouUrl,item.childPageMenus, result)
        if (isFind) {
          result.unshift(item.id)
          return true
        }
      }
    }
    //走到这说明没找到目标
    return false
  }
}
/**
 * 获取第一个有路由的子节点
 * @param {*} rouUrl 当前路由
 * @param {*} data 树形数据
 */
export function getMenuUrl(data){
  if(data.length>0){
    for (let item of data) {
      if (item.menuUrl) {
        return item.menuUrl
      }
      if (item.childPageMenus.length>0 && item.buttonMenus.length==0) {
        //根据查找到的结果往上找父级节点
        let isFind = getMenuUrl( item.childPageMenus)
        if (isFind) {
          return isFind
        }
      }
    }
    //走到这说明没找到目标
    return false
  }
}
/**
 * 通过指定值返回所有父级
 * @param {*} data 要遍历的数据
 * @param {*} target 查找目标
 * @param {*} result 用于装查找结果的数组
 */
export function findParent(data, target, result) {
  for (let item of data) {
    if (item.menuUrl === target) {
      //将查找到的目标数据加入结果数组中
      //可根据需求unshift(item.id)或unshift(item)
      result.unshift(item)
      return true
    }
    if (item.childPageMenus?.length > 0 && item.buttonMenus?.length==0) {
      //根据查找到的结果往上找父级节点
      let isFind = findParent(item.childPageMenus, target, result)
      if (isFind) {
        result.unshift(item)
        return true
      }
    }
  }
  //走到这说明没找到目标
  return false
}

/**
 * 查询树形数组中第一次出现url有值的元素
 * @param {*} tree 要遍历的数据
 * return 查找到的元素
 */
export function findFirstUrlInTree(tree) {  
  // console.log(tree);
  for (let i = 0; i < tree.length; i++) {
    const element = tree[i];
    if (element.menuUrl) {
      return element;  
    }
    if(element.childPageMenus&&element.childPageMenus.length > 0){
      const foundElement = findFirstUrlInTree(element.childPageMenus);
      // 如果找到了有url的子元素，则返回该子元素
      if (foundElement && foundElement.menuUrl) {
        return foundElement;
      }
    } 
  }  
  return null;  
} 
/**
 * 查询树形数组中是否有指定id值
 * @param {*} tree 要遍历的数据
 * return 查找到的元素
 */
export function findNodeById(tree,children,id) {  
  // 遍历树的当前层级  
  for (let i = 0; i < tree.length; i++) {  
    // 如果当前节点的id匹配  
    if (tree[i].id === id) {  
      // 返回找到的节点  
      return tree[i];  
    }  
    // 如果当前节点有子节点，递归调用findNodeById  
    if (tree[i][children] && tree[i][children].length > 0) {  
      let result = findNodeById(tree[i][children],children, id);  
      if (result) {  
        // 如果在子节点中找到匹配的节点，返回它  
        return result;  
      }  
    }  
  }  
  // 如果没有找到匹配的节点，返回null  
  return null;  
}