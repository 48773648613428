
import vtp from '../assets/js/tipconm'
import {formatTime,dateTime} from './utils'
/**
 * 身份证中间加*号
 * //str：要进行隐藏的变量  frontLen: 前面需要保留几位    endLen: 后面需要保留几位
 */
const hiddenIdCard=(str,frontLen,endLen)=>{  
  if(str){  
    var len = str.length-frontLen-endLen;
    var xing = '';
    for (var i=0;i<len;i++) {
      xing+='*';
    }
    return str.substring(0,frontLen)+xing+str.substring(str.length-endLen);
  }
}

/**
 * 会见时长过滤
 * value:开始时间
 * value2:结束时间
 * 计算两段时间的剩余时长
 */
const getTimeVal = (value,value2)=>{
  if(value){
    value = formatTime(value,'Y-M-D h:m:s')
    // setInterval(()=>{
      // value = vtp.time(value)
      let val;
      if(value2){
        value2 = formatTime(value2,'Y-M-D h:m:s')
        val = vtp.timeValFun(value,value2);
      }else{
        val = vtp.timeValFun(value);
      }
      let minutes = (val.day*24+val.hours)*60+val.minutes
      return  (vtp.addZero(minutes)+ '分'+vtp.addZero(val.seconds)+'秒');
    // },1000)
  }else{
    return '00分00秒'
  }
}

/**
 * 会见时长过滤
 * value:开始时间戳
 * timeNum:会见时长：单位（分钟）
 * 计算两段时间的剩余时长
 */
const getTimeStyle = (value,timeNum)=>{
  if(value){
    value = formatTime(value,'Y-M-D h:m:s')
    let val;
    val = vtp.timeValFun(value);
    let minutes = (val.day*24+val.hours)*60+val.minutes;//计算分钟数
    let num = timeNum ? timeNum : 40
    //判断分钟是否大于num
    if(minutes > num){
      return {'color':'red'}
    }else{
      return ''
    }
  }else{
    return ''
  }
}

/**
 * 
 */
const ifAddRedState = (value)=>{
  if(value){
    let val = value.split(":")
    let html = val[0] < 20 ? '0' : '1'
    return  html ;
  }
}

const secondToTimeStr = (t = 0)=>{
    if (!t) return;
    if (t < 60) return "00:" + ((i = t) < 10 ? "0" + i : i);
    if (t < 3600) return "" + ((a = parseInt(t / 60)) < 10 ? "0" + a : a) + ":" + ((i = t % 60) < 10 ? "0" + i : i);
    if (3600 <= t) {
      var a, i, e = parseInt(t / 3600);
      return (e < 10 ? "0" + e : e) + ":" + ((a = parseInt(t % 3600 / 60)) < 10 ? "0" + a : a) + ":" + ((i = t % 60) < 10 ? "0" + i : i);
    }
}
//传入时间戳返回时间点
const getTimeStr = (value)=>{
  if(value){
    return  dateTime(value);
  }else{
    return ''
  }
}
//标准时间转时分秒
const getformatTimeStr = (timestamp) =>{
  var date = new Date(timestamp);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  hours = hours<10 ? '0'+hours : hours
  minutes = minutes<10 ? '0'+minutes : minutes
  seconds = seconds<10 ? '0'+seconds : seconds
  return hours + ":" + minutes + ":" + seconds;
}

export {
  hiddenIdCard,
  getTimeVal,
  ifAddRedState,
  getTimeStyle,
  secondToTimeStr,
  getTimeStr,
  getformatTimeStr
}