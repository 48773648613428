//services/global.js"
import axios from 'axios';
import { Message, Loading } from 'element-ui'
import vtp from '@/assets/js/tipconm'
import router from '../router'
import store from '../store/index'
import VueCookie from "vue-cookie";

let loadingInstance = null //这里是loading
// let ConfigBaseURL;
// if(process.env.NODE_ENV !='development'){
    // ConfigBaseURL = 'https://localhost:8080/api'
// }else{
    // ConfigBaseURL = 'http://192.16.16.37:8080/api/'
// }

let errCodeNum = 1;//授权许可错误返回次数
let errCodeNum2 = 1;//网络错误显示次数
let errCodeNum3 = 1;//token失效显示次数
// 初始化实例
const http = axios.create({
    // timeout: 7000, // 请求超时时间
    // baseURL: ConfigBaseURL,
    // method: 'post',
    // headers: {
    //   'Content-Type': 'application/json'
    // }
});

axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.timeout = 20000;//请求超时时间20s
// http.dicUrl = '/dicapis/sysDicFeign/getDicItemList/';
// 设置请求头
// http.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
// 请求拦截器
axios.interceptors.request.use((config) => {
  //云台和获取监控地址接口不需要loading
  if(!config.url.includes('/deviceMedia/live')&&!config.url.includes('/deviceChannel/ptzStart')
      &&!config.url.includes('/deviceChannel/ptzStop')&&!config.url.includes('/stats/user/alarm')
      &&!config.url.includes('/home/getHome')&&!config.url.includes('/main/getMainPage')){
    loadingInstance = Loading.service({
      lock: false,
      text: '请求数据中...',
      background:'rgba(0,0,0,.7)',
    })
  }
  //判断请求体不为null或者不为undefined且不是formData请求
  if(config.data!=null && config.data!=undefined && !(config.data instanceof FormData)){
    
  }
  // console.log('请求:',config.data);
  // config.headers.common['token'] = VueCookie.get("token")
  config.headers['SECURITY-AUTH-TOKEN'] = VueCookie.get("token")
  return config;
}, (error) => { return Promise.reject(error); });
// 响应拦截器
axios.interceptors.response.use((response) => {
    loadingInstance?.close()
    // 2001, token失效
    if (response.data && response.data.code === 2001) { // 2001, token失效
      if(errCodeNum3 == 1){
        store.state.token = ''
        VueCookie.get('token') ? vtp.clearLoginInfo():''
        router.push({ name: 'login' })

        errCodeNum3+=1
        setTimeout(()=>{
          errCodeNum3 = 1
        },3000)
        return response;
      }else{
        return null;
      }
    }else if(response.data && (response.data.code === 101||response.data.code === 102)){//授权许可未授权或已过期
      
      if(errCodeNum==1){
        vtp.clearLoginInfo()
        store.state.authLicence = true
        errCodeNum+=1
        setTimeout(()=>{
          errCodeNum = 1
        },3000)
        return response;
      }else{
        return null;
      }
    }else{
      return response;
    }
}, (error) => {
    const msg = error.Message !== undefined ? error.Message : ''
    // Message({
    //   message: '网络错误' + msg,
    //   type: 'error',
    //   duration: 3000
    // })
    loadingInstance?.close()
    let errorData = {
      data:{
        message: '网络错误' + msg,
        data:{},
        code:-1
      }
    }
    if(errCodeNum2 == 1){
      
      errCodeNum2+=1
      setTimeout(()=>{
        errCodeNum2 = 1
      },3000)
      // return Promise.reject(error);
      return errorData
    }
} );

// 封装axios的post请求
http.post = (url, params,timeNum) => {
  return new Promise((resolve, reject) => {
    let obj = {headers:{'Content-type':'application/json'}}
    if(timeNum) obj.timeout = timeNum
    axios.post(url, params, obj).then(response => {
      resolve(response?.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}
// 封装axios的post下载文件请求
http.postFile = (url, params) => {
  return new Promise((resolve, reject) => {
    const headConfig = {
      responseType: 'arraybuffer' // 设置响应类型
    }
    axios.post(url, params,{...headConfig}).then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
  });
}
http.postJson = (url, params,timeNum) => {
  return new Promise((resolve, reject) => {
    let obj = {headers:{'Content-type':'application/x-www-form-urlencoded; charset=UTF-8'}}
    if(timeNum) obj.timeout = timeNum
    axios.post(url, params,obj).then(response => {
      resolve(response?.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}
// 封装axios的put请求
http.put = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.put(url, params).then(response => {
      resolve(response?.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}
// 封装axios的put请求
http.putForm = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.put(url, params,{headers:{'Content-type':'application/x-www-form-urlencoded; charset=UTF-8'}}).then(response => {
      resolve(response?.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}
// 封装axios的put请求
http.delete = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.delete(url, params).then(response => {
      resolve(response?.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}
// 封装axios的get请求
http.get = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.get(url, params).then( response => {
      resolve(response?.data)
    })
    .catch( err => {
      reject(err)
    })
  })
}
// 封装axios的get请求二进制文件
http.getFile = (url, params,timeNum) => {
  return new Promise((resolve, reject) => {
    let obj = {headers:{'Content-type':'application/json'}}
    if(timeNum) obj.timeout = timeNum
    axios.get(url, {params:params,responseType:'arraybuffer'},obj).then( response => {
      resolve(response)
    })
    .catch( err => {
      reject(err)
    })
  })
}
// 封装axios的post请求
http.downPost = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.post(url, params,{headers:{'Content-type':'application/json'}}).then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err);
    });
  });
}

export default http;
