<template>
  <div class="headComp">
    <!-- <div class="logoDiv" @click="goToSun('/system-person')"><img :src="appInfo.logoPicImg" alt=""></div> -->
    <div class="csSelDiv">
      <div class="label">区域:</div><el-cascader v-model="csType" :options="zoneData" size="mini" :props="cascProps" :show-all-levels="false" placeholder="请选择区域"></el-cascader>
      <template v-if="isShowCS">
      <div class="label ml10">场所:</div>
      <el-select v-model="homeId" placeholder="场所" class="w80" size="mini">
        <el-option v-for="(item,index) in homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
      </el-select>
      </template>
    </div>
    <div class="headBg">
      <div class="bg bgLeft"></div>
      <div class="name">
        <div class="img"><img src="../assets/images/ui/title_l.png" alt=""></div>
        <div class="title" ref="headTitRef">{{appInfo.appShowName}}</div>
        <div class="img"><img src="../assets/images/ui/title_r.png" alt=""></div>
      </div>
      <div class="bg bgRight"></div>
    </div>
    <div class="buttonMenu" v-if="$route.path!='/login'">
      <div :style="{'marginRight':item.isMg && mrWidth}" :class="['item',{'active':headActiveApp==item.id,'noShow':item.menuCode === 'safe:system','isVbl':item.isShow==0,'isLeft':item.isLeft,'leftNav':item.leftNav,'rightNav':item.rightNav}]" v-for="(item,index) in menuDataApp" :key="index" @click="handleSelect(item.id,'toTwo')">
        <!-- <i :class="item.iconUrl?item.iconUrl:''"></i> -->
        <div class="name">{{item.menuName}}</div>
      </div>
    </div>
    <div class="head_right">
      <div @click="goToSun('/index')" class="rItem mr20 icon icon-home" title="返回首页"></div>
      <div @click="goToSystem()" v-if="isSystem" class="rItem mr20 el-icon-s-tools" title="系统管理"></div>
      <div class="rItem mr20" :class="!isFullScreen?'icon icon-quanping':'icon icon-quxiaoquanping'" :title="isFullScreen?'退出全屏':'全屏'" @click="toggleFullScreen"></div>
      <el-badge class="rItem mr20 notifyBell" :value="notifyNum" :max="99" @click.native="openDio('notify')">
        <i class="el-icon-bell"></i>
      </el-badge>
      <el-dropdown>
        <!-- <i class="avatar el-icon-user-solid"></i> -->
        <div class="avatar flex-c"><el-avatar class="mr5" icon="el-icon-user-solid" size="small"/><span class="account" :title="userInfo.account">{{userInfo.account}}</span></div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="toRouterPage('/personalCenter')">个人中心</el-dropdown-item>
          <!-- <el-dropdown-item>控件下载</el-dropdown-item> -->
          <!-- <el-dropdown-item @click.native="openDio('zoneSetting')">区域设置</el-dropdown-item> -->
          <el-dropdown-item @click.native="openDio('licence')">授权信息</el-dropdown-item>
          <!-- <el-dropdown-item @click.native="openDio('manual')">操作手册</el-dropdown-item> -->
          <el-dropdown-item @click.native="openDio('changePw')">修改密码</el-dropdown-item>
          <!-- <el-dropdown-item @click.native="openDio('version')">版本信息</el-dropdown-item> -->
          <el-dropdown-item class="error" @click.native="clickLogout()">退出系统</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- <licence-comp ref="licenceRef"></licence-comp> -->
    <!-- <change-pw ref="changePwRef"></change-pw> -->
    <!-- <version ref="versionRef"></version> -->
    <!-- <zone-setting ref="zoneRef"></zone-setting> -->

    <notify-drawer ref="notifyRef" @notifyNumFun="notifyNumFun"></notify-drawer>
  </div>
</template>
<script>
import notifyDrawer from '@/components/notifyDrawer'
import { apiUrl,globalStr } from '@/assets/js/api';
import qs from 'qs'
// import licenceComp from '@/components/licence'
// import changePw from '@/components/changePw'
// import Version from './version.vue';
// import zoneSetting from './zoneSetting.vue';
import { findFirstUrlInTree,delChildren2,findNodeById } from '@/utils/utils'
var vm;
export default {
  props:['menuDataApp','headActiveApp','isSystem'],
  data() {
    return {

      notifyNum:0,//消息数

      appInfo:{
        logoPicImg:'',//平台logo
        appShowName:'',//平台名称
      },
      isActive:'/index',
      menuData:[],

      isFullScreen:false,//是否全屏

      csType:this.$store.state.csType,//区域ID
      zoneData:this.$store.state.zoneData,//区域数据
      cascProps:{
        value:'id',
        label:'areaName',
        children:'childrenList',
        checkStrictly:true,
        emitPath:false,
      },
      homeId:this.$store.state.homeId,//场所ID
      homeList:this.$store.state.homeList,//场所列表

      userInfo:{
        account:'',
      },

      isShowCS:true,

      mrWidth:'0px'
    }
  },
  components:{
    // licenceComp,
    // changePw,
    notifyDrawer,
    // Version,
    // zoneSetting
  },
  created() {
    vm = this;
  },
  mounted() {
    let currHeadActive = JSON.parse(sessionStorage.getItem('headActive'))
    if(currHeadActive){
      this.isActive = currHeadActive
    }
    let platInfo = this.vtp.get('platInfo')
    if(platInfo){
      this.appInfo = {
        // logoPicImg: platInfo.iconFile.fileRelativePath?`${globalStr}/fileView${platInfo.iconFile.fileRelativePath}`:'',
        logoPicImg:'',
        appShowName:platInfo.appShowName,
      }
    }
    let token = this.$cookie.get('token')
    if(token){
      // this.getAreaList()
      let userInfo = this.vtp.get('userInfo')
      if(userInfo){
        this.userInfo.account = userInfo.account
      }
    }
    vm.$nextTick(()=>{
      //获取名称所在div的宽度后加120px返回父组件
      let width = vm.$refs.headTitRef.offsetWidth
      // vm.$emit('mright',width+100)
      vm.mrWidth = `${width+120}px`
    })
  },
  watch:{
    '$route':{
      handler:function(newVal){
        this.isActive = newVal.path
        if(newVal.meta.isShowCS){
          this.isShowCS = true
        }else{
          this.isShowCS = false
        }
      },immediate: true,deep: true
    },
    '$store.state.changeAreaNum':function(newVal){
      this.getAreaList()
    },
    '$store.state.csType':function(newVal){
      this.csType = newVal
      this.getHome(newVal)
    },
    '$store.state.zoneData':function(newVal){
      this.zoneData = newVal
    },
    csType(newVal,oldVal){
      this.$store.state.homeId = '0'
      this.$store.state.csType = newVal
    },
    '$store.state.homeId':function(newVal){
      this.homeId = newVal
    },
    '$store.state.homeList':function(newVal){
      this.homeList = newVal
    },
    homeId(newVal,oldVal){
      this.$store.state.homeId = newVal
    },
    '$store.state.appShowName':function(newVal){
      this.appInfo.appShowName = newVal
      vm.$nextTick(()=>{
        //获取名称所在div的宽度后加120px返回父组件
        let width = vm.$refs.headTitRef.offsetWidth
        // vm.$emit('mright',width+100)
        vm.mrWidth = `${width+120}px`
      })
    },
    // '$store.state.authLicence':function(newVal){
    //   this.openDio('licence')
    // }
  },
  methods:{
    //获取树形区域数据
    async getAreaList(){
      const res = await apiUrl.getAreaList()
      if(res?.code==200){
        this.zoneData = res.data
        delChildren2(this.zoneData,'childrenList')
        if(this.zoneData.length>0){
          if(this.csType==''){
            this.csType = this.zoneData[0].id
          }else{
            let isID = findNodeById(this.zoneData,'childrenList',this.csType)
            if(!isID){
              this.csType = this.zoneData[0].id
            }
          }
          this.getHome(this.csType)
        }
      }else{
        this.$message.error(res.message)
      }
    },
    //获取场所
		async getHome(csType){
      let dataObj = {
        'areaId': csType
      }
      const res = await apiUrl.getHome(qs.stringify(dataObj))
      if(res?.code == 200){
        let arr = JSON.parse(JSON.stringify(res.data))
        let obj = {
          id:'',
          homeName:'全部',
        }
        arr.unshift(obj)
        this.homeId = arr[0]?.id
        this.homeList = arr
        this.$store.state.homeList = arr
      }
		},
    toggleFullScreen() {  
      if (!document.fullscreenElement &&  
          document.documentElement.requestFullscreen) {  
        document.documentElement.requestFullscreen()  
          .then(() => {  
            this.isFullScreen = true;  
          })  
          .catch((err) => {  
            console.error('Error attempting to enable full-screen mode:', err);  
          });  
      } else if (document.exitFullscreen) {  
        document.exitFullscreen()  
          .then(() => {  
            this.isFullScreen = false;  
          })  
          .catch((err) => {  
            console.error('Error attempting to exit full-screen mode:', err);  
          });  
      }  
    },
    //跳转到子页面
    goToSun(url){
      this.$router.push({
        path: url
      })
    },
    //跳转到系统管理
    goToSystem(){
      let menuData = this.vtp.getSession('menuData')
      if(menuData?.length>0){
        //拿到指定的菜单编码所属的菜单
        let sysMenu = menuData.filter(item => item.menuCode === 'safe:system');
        let firstUrl = findFirstUrlInTree(sysMenu)
        if(firstUrl){
          this.$router.push({
            path:firstUrl.menuUrl
          })
        }
      }
      
    },
    //消息组件返回
    notifyNumFun(val){
      this.notifyNum = val
    },
    //根据当前登录用户获取应用的页面菜单树
    async getPageMenuTreeCurrUser () {
      const res = await apiUrl.getPageMenuTreeCurrUser()
      if(res?.code==200){
        let authArr = getAuthMenu(res.data)
        // console.log('authArr',authArr.join(','));
        vm.vtp.setSession('permissions',authArr.join(','))
        vm.vtp.setSession('menuData',res.data)
        vm.menuData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //打开弹窗
    openDio(type){
      //修改密码
      if(type=='changePw'){
        // this.$refs.changePwRef.init()
        this.$emit('openDioHead',type)
      }else if(type=='licence'){//授权信息
        this.$emit('openDioHead',type)
      }else if(type=='notify'){
        this.$refs.notifyRef.init()
      }else if(type=='manual'){//操作手册
        this.templateFileDownload()
      }else if(type=='version'){//版本信息
        this.$refs.versionRef.init()
      }
      // else if(type=='zoneSetting'){//区域设置
      //   // this.$refs.zoneRef.init()
      //   this.$emit('openDioHead',type)
      // }
    },
    //下载操作手册
    async templateFileDownload(){
      const res = await apiUrl.templateFileDownload('OPERATION_MANUAL');//OPERATION_MANUAL:操作手册
      if(!res) return;
      //fileName:下载的文件名
      var fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1])
      let blob = new Blob([res.data], {type: res.headers['content-type']});
      
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob); //常见下载的链接
      downloadElement.href = href;
      downloadElement.download = fileName; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
    },
    //跳转到个人中心
    toRouterPage(pageUrl){
      this.$router.push({
        path: pageUrl,
      })
    },
    //点击菜单
    handleSelect (key, keyPath) {
      // sessionStorage.setItem('headActive',JSON.stringify(key))
      this.$parent.handleSelect(key, keyPath)
    },
    
    //退出确认
    clickLogout(){
      vm.$confirm('确认退出当前登录？','提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
      .then(() => {
        vm.logoutFun()
      })
      .catch(() => {})
    },
    //退出登录
    async logoutFun (){
      let dataObj = {
        'token': vm.$cookie.get('token')
      }
      const res = await apiUrl.logout(qs.stringify(dataObj))
      if(res?.code==200){
        vm.$message.success('退出登录')
        vm.$store.state.token = ''
        vm.vtp.clearLoginInfo()
        vm.$router.push({
          path:'/login'
        })
      }else{
        vm.$message.error(res.message)
      }
      
    },
  }
}
</script>
<style lang="scss">
.headComp{
  text-align:center;
  z-index: 100;
  height:57px;
  box-sizing: border-box;
  position:relative;
  .csSelDiv{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    display: inline-flex;
    align-items: center;
    z-index: 1;
    .label{
      margin-right: 5px;
      color: rgba(255,255,255,0.5);
    }
    .w80{
      width: 80px;
    }
    .el-select{
      .el-input__inner{
        background-color: transparent;
        color: #00c6ff;
        border-color: #1d4975;
      }
      .el-input__suffix{
        color: #26629e;
      }
    }
    .el-cascader{
      width: 80px;
      .el-input__suffix{
        color: #26629e;
      }
      .el-input__inner{
        background-color: transparent;
        color: #00c6ff;
        border-color: #1d4975;
      }
    }
  }
  .headBg{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    .bg{
      z-index: 0;
      flex-grow: 1;
      &.bgLeft{
        background: url('../assets/images/ui/head_l.png') right top no-repeat;
        background-size: 100% 100%;
      }
      &.bgRight{
        background: url('../assets/images/ui/head_r.png') left top no-repeat;
        background-size: 100% 100%;
      }
    }
    .name{
      flex-shrink: 0;
      // padding :10px 0;
      font-size:24px;
      color: #fff;
      font-weight: bold;
      letter-spacing:3px;
      height: 93px;
      display: inline-flex;
      text-align: center;
      z-index: 1;
      // width: 36%;
      min-width: 36%;
      .title{
        background: url('../assets/images/ui/title_c.png');
        background-size: 100% 93px;
        line-height: 80px;
        width: calc(100% - 162px);
        font-size: 36px;
      }
      .img{
        height: 100%;
        img{height: 100%;}
      }
    }
  }
  .logoDiv{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
    height: 40px;
    cursor: pointer;
    img{
      height: 100%;
    }
  }
  .head_right{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 2;
    .rItem{
      font-size: 20px;
      cursor: pointer;
      color: #fff;
    }
    .notifyBell{
      i{
        font-size: 20px;
      }
    }
    .avatar{
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      .account{
        max-width: 53px;
        overflow: hidden;
      }
    }
  }
}
</style>