import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/common.scss";

// 引入字体图标
import "./assets/css/font/iconfont.css";

import directive from './utils/directive'
Vue.use(directive)

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

//引入表格滚动插件
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import VueCookie from "vue-cookie";
Vue.use(ElementUI);
Vue.use(VueCookie);

import Axios from "./axios/index";
import vtp from "./assets/js/tipconm";

Vue.prototype.vtp = vtp;
Vue.prototype.$http = Axios;

Vue.config.productionTip = false;

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

// 引入md5加密
import md5 from "js-md5";
Vue.prototype.$md5 = md5;

// 引入拖动插件
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })

// 过滤器
import * as filters from "./utils/filters.js";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 权限方法
import { isAuth } from "@/utils/utils";
Vue.prototype.isAuth = isAuth;


//鼠标右键
import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);

//swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper)
import Swiper2, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper'
Swiper2.use([Navigation, Pagination, EffectFade, Autoplay])

//引入公共组件-查看监控
import numChange from "@/components/numChange"
Vue.component("num-change",numChange);

//绘制连线
import jsPlumb from 'jsplumb'
Vue.prototype.$jsPlumb = jsPlumb.jsPlumb

//路由拦截
router.beforeEach((to, from, next) => {
  let islogin = VueCookie.get("token");
  if (to.meta.requireAuth) {   //是否需要登录才能访问该页面
    if (islogin) {   //判断是否登录过
      next();
    } else {
      next("/login");
    }
  }else {
    if(to.path == '/login'){
      if (islogin) {   //判断是否登录过
        next('/index');
      } else {
        next();
      }
    }else{
      next();
    }
  }
});

const newVue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
export default newVue;