import store from '@/store'
import VueCookie from "vue-cookie";
import vtp from '@/assets/js/tipconm'

// import { vtp } from '../assets/js/tipconm';

var wsurl = ''
var websock = null;
// var count = 0;//断开重连计数
// var MAX = 1000;//最大重连次数
var connetClose = '';//重连定时器
var sendMsg = null;

//初始化weosocket 
const initWebSocket = (url,msg) =>{
  if(websock){
    websocketclose()
  }
  // wsurl = url ? wsurl+url : wsurl
  if(!url){
    // var wsurl = `${location.host}/apis/broadcastWs/common/${VueCookie.get("token")}`
    let userInfo = vtp.get('userInfo')
    var wsurl = `${location.host}/apis/message/wss/${userInfo?.id}`
  }
  wsurl = url ? `${location.protocol == 'https:' ? 'wss' : 'ws'}://${url}` : `${location.protocol == 'https:' ? 'wss' : 'ws'}://${wsurl}`
  sendMsg = msg ? msg : sendMsg
  //wsurl:地址，gkws_v1：协议名称（多个可以用 ['protocol1','protocol2',...]）
  
  websock = new WebSocket(wsurl);
  websock.onopen = websocketonopen;
  websock.onerror = websocketonerror;
  websock.onmessage = websocketonmessage; 
  websock.onclose = websocketclose;
}
const websocketonopen = ()=> {
  // console.log("ws连接成功");
  if(sendMsg){
    websock.send(sendMsg)
    // console.log('wssend1',sendMsg)
  }
}
//错误
const websocketonerror =(e) =>{ 
  // console.log("ws连接异常",e);
  websock.close()
  // console.log('准备重连error')
  reconnection()
}
//数据接收 
const websocketonmessage = (e)=>{
  // console.log('接收：',e);
  const redata = JSON.parse(e.data);
  
  //注意：长连接我们是后台直接1秒推送一条数据， 
  //但是点击某个列表时，会发送给后台一个标识，后台根据此标识返回相对应的数据，
  //这个时候数据就只能从一个出口出，所以让后台加了一个键，例如键为1时，是每隔1秒推送的数据，为2时是发送标识后再推送的数据，以作区分
  // redata = JSON.parse(redata)
  if(redata){
    store.commit('wsMsg',redata)
    // console.log('ws接收',redata)
    // sessionStorage.setItem('wsMsg',redata)
  }
}
//数据发送 
const websocketsend = (agentData)=>{
  // console.log('ws发送',agentData)
  try{
    
    websock.send(agentData);
  }catch{}
  
}
//ws断开重连
const reconnection = () =>{
  // count = count + 1;
  
  //1与服务器已经建立连接
  if (websock.readyState == 1) {
    // console.log('ws重连成功')
    clearTimeout(connetClose);
    // count = 0
  } else {
    //3已经关闭了与服务器的连接
    if (websock.readyState == 3) {
      // count = 0
      clearTimeout(connetClose);
      initWebSocket();
    }else{
      //0正尝试与服务器建立连接,2正在关闭与服务器的连接
      // console.log('准备重连')
      connetClose = setTimeout(function() {
        reconnection();
      }, 1000);
    }
  }
  
}
//关闭 
const websocketclose = (e) =>{
  console.log("ws已关闭",e);
  // console.log(e?.code + ' ' + e?.reason + ' ' + e?.wasClean)
  // websock.close()
 //1000属于正常关闭
  if(e){
    //判断是否是1000正常关闭||1001或终端离开或服务器错误
    if(e.code=='1000'||e.code=='1001'){
      websock?.close()
    }else{
      let userInfo = vtp.get('userInfo')
      if(userInfo){
        reconnection()
      }
    }
  }else{
    websock?.close()
  }
  
}

export {
  initWebSocket,
  websocketonopen,
  websocketonerror,
  websocketonmessage,
  websocketsend,
  websocketclose,
  reconnection
}