<template>
  <div class="licence">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDiv">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="授权版本:" prop="appShowName">
            <p>{{inform.tryout?'试用版本':'正式版本'}}</p>
          </el-form-item>
          <el-form-item label="有效日期:" prop="expirationDate">
            <p>{{inform.expirationDate}}</p>
          </el-form-item>
          <el-form-item label="登录限制人数:" prop="onlineUserCount">
            <p>{{inform.onlineUserCount}}</p>
          </el-form-item>
          <el-form-item label="摄像头数量:" prop="cameraCount">
            <p>{{inform.cameraCount}}</p>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" size="small" @click="authLicenseDownload()">下载机器码</el-button>
        <el-upload
          class="uploader"
          action="#"
          :show-file-list="false"
          :http-request="httpRequest"
          :before-upload="beforeUpload">
          <el-button type="primary" size="small">更新授权</el-button>
        </el-upload>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
let vm;
export default {
  props:[],
  data() {
    return {
      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      inform :{
        tryout:false,//true试用版本，false正式版本
        expirationDate:'',//有效日期
        cameraCount:'',//摄像头数量
        onlineUserCount:'',//登录限制人数
      },
      rules :{
        
      },
    }
  },
  created(){
    vm = this
    
  },
  methods:{
    //初始化
    init (){
      this.dioWidth = '600px'
      this.dioTit = '授权信息'
      this.authLicenseInfo()
      this.isDio = true
    },
    //查询当前登录应用的详细数据
    async authLicenseInfo (){
      const res = await apiUrl.authLicenseInfo()
      if(res?.code==200){
        vm.inform.tryout = res.data.tryout//版本类型true试用版本，false正式版本
        vm.inform.expirationDate = res.data.expirationDate//有效日期
        vm.inform.cameraCount = res.data.cameraCount//摄像头数量
        vm.inform.onlineUserCount = res.data.onlineUserCount//登录限制人数
      }else{
        vm.$message.error(res.message)
      }
    },
    //下载机器码
    async authLicenseDownload(){
      const res = await apiUrl.authLicenseDownload()
      if(!res) return;
      //fileName:下载的文件名
      var fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1])
      let blob = new Blob([res.data], {type: res.headers['content-type']});
      
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob); //常见下载的链接
      downloadElement.href = href;
      downloadElement.download = fileName; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
    },
    beforeUpload (file){
      const isLIC = file.name.split('.').pop().toLowerCase() === 'lic';  
      const isLt500M = file.size / 1024 / 1024 < 500
      if (!isLIC) {
        vm.$message.warning('上传文件只能是.lic 格式!')
      }
      if (!isLt500M) {
        vm.$message.warning('上传文件大小不能超过 500MB!')
      }
      return isLIC && isLt500M
      
    },
    httpRequest (data) {
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        vm.authLicenseUpload(data.file)
      }
    },
    //上传授权文件
    async authLicenseUpload(file){
      let dataObj = new FormData();
      dataObj.append("licenseFile ", file||'');
      const res = await apiUrl.authLicenseUpload(dataObj)
      if(res?.code==200){
        this.$message.success('上传成功！')
        this.authLicenseInfo()
      }else{
        this.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio (formEl){
      // this.$nextTick(() => {
      this.$refs[formEl].resetFields()
      // })
      this.isDio = false
    },
  }
}
</script>
<style lang="scss">
.licence{
  .uploader{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>