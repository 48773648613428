<template>
  <div id="app" v-cloak>
    <head-comp ref="headRef" v-if="isShowHead" :isSystem="isSystem" :menuDataApp="menuData" :headActiveApp="headActive" :appInfo="appInfo" @openDioHead="openDio" @mright="headMr"></head-comp>
    <el-container>
    <el-aside class="asideMenu" v-if="hasChilMenu">
      <el-menu :default-active="isActive" unique-opened router @select="menuSelect" text-color="#fff" active-text-color="#fff">
        <tree-menu :menuData="childMenu"></tree-menu>
      </el-menu>
    </el-aside>
    <el-main style="padding: 0;">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </el-main>
    </el-container>

    <licence-comp ref="licenceRef"></licence-comp>
    <change-pw ref="changePwRef"></change-pw>
    <!-- <zone-setting ref="zoneRef"></zone-setting> -->
  </div>
</template>
<script>
import licenceComp from '@/components/licence'
import changePw from '@/components/changePw'
// import zoneSetting from '@/components/zoneSetting.vue';
import headComp from '@/components/head'
import { apiUrl,globalStr } from '@/assets/js/api';
import qs from 'qs'
import { initWebSocket,websocketclose } from '@/utils/websocket'
import { getAuthMenu,findParent,findFirstUrlInTree,delChildren2 } from '@/utils/utils'
export default {
  provide() {  
    return {  
      getAreaList: this.getAreaList,  
    };  
  },  
  data() {
    return {
      isShowHead: false,//是否显示头部

      hasChilMenu:false,//是否有子菜单

      isSystem:true,//是否有系统菜单

      headActive:'',//底部菜单当前选中
      menuData:[],//菜单列表
      isActive:'',
      childMenu:[],//子菜单列表

      appInfo: {
        logoPicImg:'',//平台logo
        appShowName:'',//平台名称
      },

      serverUrl:'',
      wsUrl:'',

      mrWidth:'0px',//动态设置head菜单栏的margin-right
      
    }
  },
  components:{
    headComp,
    licenceComp,
    changePw,
    // zoneSetting,
    treeMenu:()=>import('@/components/tree-menu.vue'),
  },
  watch:{
    '$route':function(newVal,oldVal) {
      let str = this.menuData.find((li)=>{
        return li.menuUrl == newVal.path;//筛选出匹配数据
      });
      //如果当前路由是一级菜单
      if(str){
        this.hasChilMenu = false
        this.headActive = str.id
      }else if(newVal.meta.isIndependent){//如果路由是独立的
        this.hasChilMenu = false
        this.headActive = ''
        this.isActive = ''
        this.vtp.setSession('headActive','')
        this.vtp.setSession('asideActive','')
      }else{//一级以后的菜单，需要显示左侧栏
        this.hasChilMenu = true
      }
      //判断路由是否不显示头部
      if(newVal.meta.isNoHead){
        this.isShowHead = false
        this.hasChilMenu = false
      }else{
        this.isShowHead = true
      }
      let result = []
      findParent(this.menuData, newVal.path, result)
      if(result.length>0){
        this.handleSelect(result[0].id)
        if(result.length>1){
          this.vtp.setSession('asideActive',result[result.length-1].menuUrl)
          this.isActive = result[result.length-1].menuUrl
        }
      }
    },
    '$store.state.token':function(newVal,oldVal) {
      if(!newVal){
        websocketclose()
      }else{
        initWebSocket()
        this.getPageMenuTreeCurrUser()
      }
    },
    '$store.state.wsMsg':function(newVal,oldVal) {
      this.getSkMsg(newVal)
    },
    '$store.state.authLicence':function(newVal){
      this.openDio('licence')
    },
  },
  async created(){
    this.getAppVo()
    let token = this.$cookie.get('token')
    if(token){
      await this.getAreaList()
      initWebSocket()
      this.getPageMenuTreeCurrUser()
    }
  },

  mounted(){
    
  },
  methods: {
    //获取树形区域数据
    async getAreaList(){
      const res = await apiUrl.getAreaList()
      if(res?.code==200){
        let arr = res.data
        delChildren2(arr,'childrenList')
        this.$store.state.zoneData = res.data
        if(arr.length>0){
          this.$store.state.csType = arr[0].id
          await this.getHome(arr[0].id)
        }
      }else{
        this.$message.error(res.message)
      }
    },
    //获取场所
		async getHome(csType){
      let dataObj = {
        'areaId': csType
      }
      const res = await apiUrl.getHome(qs.stringify(dataObj))
      if(res?.code == 200){
        let arr = JSON.parse(JSON.stringify(res.data))
        let obj = {
          id:'',
          homeName:'全部',
        }
        arr.unshift(obj)
        this.$store.state.homeId = arr[0]?.id
        this.$store.state.homeList = arr
      }
		},
    //接收到head组件返回的名称所在div的宽度+100px
    headMr(val){
      this.mrWidth = `${val}px`
    },
    //websocket接收
    getSkMsg(res) {
      //被其他用户登录，强制退出
      if(res?.type=='loginKickOut'){
        this.$message.warning('该账号已在其他设备登录！')
        this.$store.state.token = ''
        this.vtp.clearLoginInfo()
        this.$router.push({
          path:'/login'
        })
      }
      
    },
    //打开弹窗
    openDio(type){
      if(type=='licence'){//授权信息
        this.$refs.licenceRef.init()
      }else if(type=='changePw'){
        this.$refs.changePwRef.init()
      }
      // else if(type=='zoneSetting'){//区域设置
      //   this.$refs.zoneRef.init()
      // }
    },
    //查询当前登录应用的详细数据
    async getAppVo (){
      let dataObj = {
        'appCode':'APP_SECURITY_PC'
      }
      const res = await apiUrl.getAppVo(qs.stringify(dataObj))
      if(res?.code==200){
        // this.appInfo.logoPicImg = res.data.logoFile?`${globalStr}/fileView${res.data.logoFile.fileRelativePath}`:''
        this.appInfo.logoPicImg = ''
        this.appInfo.appShowName = res.data.appShowName
        document.title = res.data.siteTitle;//设置网页标题
        let iconUrl = res.data.iconFile? `${globalStr}/fileView${res.data.iconFile.fileRelativePath}`:''
        if(iconUrl){
          this.changeFavicon(iconUrl);
        }
        this.vtp.set('platInfo',res.data)
      }else{
        this.$message.error(res.message)
      }
    },
    //修改网站icon
    changeFavicon (url) {
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = url;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.type = "image/x-icon";
        $favicon.href = url;
        document.head.appendChild($favicon)
      }
    },
    //根据当前登录用户获取应用的页面菜单树
    async getPageMenuTreeCurrUser () {
      const res = await apiUrl.getPageMenuTreeCurrUser()
      if(res?.code==200){
        if(res.data?.length>0){
          let authArr = getAuthMenu(res.data)
          this.vtp.setSession('permissions',authArr.join(','))
          this.vtp.setSession('menuData',res.data)
          let arr = JSON.parse(JSON.stringify(res.data))
          // 找到系统管理这个元素的索引  
          let indexItem = arr.findIndex(item => item.menuCode === 'safe:system');  

          var halfCeil = indexItem==-1?Math.ceil((res.data.length) / 2):Math.ceil((res.data.length-1) / 2);  
          // console.log(halfCeil);
          // 注意：这个索引可能超出了数组的实际范围（如果数组长度为偶数）  
          // 所以你可能需要确保索引在数组范围内  
          var index = Math.min(halfCeil - 1, res.data.length - 1); // 如果halfCeil超出了数组范围，就使用最后一个元素的索引 
          
          
          // 如果找到了该元素  
          if (indexItem !== -1) { 
            for (let i = 0; i <= index+1; i++) {
              arr[i].isLeft = true
            } 
            // 使用splice方法移除该元素并返回它  
            let elementToMove = arr.splice(indexItem, 1)[0];  
            // 然后将该元素unshift到数组的开头 
            arr.unshift(elementToMove);  
            this.isSystem = true 
          }else{
            for (let i = 0; i <= index; i++) {
              arr[i].isLeft = true
            }
            this.isSystem = false
          }
          if(this.isSystem == true){
            arr[index+1].isMg = true
            if(arr[index+1]) arr[index+1].leftNav = true
            if(arr[index+2]) arr[index+2].rightNav = true
            if(((arr.length-1) % 2) > 0 ){
              arr.push({
                id:'1',
                menuName:'多余',
                iconUrl:'',
                isShow:0
              })
            }
          }else{
            arr[index].isMg = true
            if(arr[index]) arr[index].leftNav = true
            if(arr[index+1]) arr[index+1].rightNav = true
            if(((arr.length) % 2) > 0 ){
              arr.push({
                id:'1',
                menuName:'多余',
                iconUrl:'',
                isShow:0
              })
            }
          }
          
          
          this.menuData = arr

          let result = []
          findParent(res.data, this.$route.path, result)
          if(result.length>0){
            this.headActive = result[0].id
            this.handleSelect(result[0].id)
            if(result.length>1){
              this.vtp.setSession('asideActive',result[result.length-1].menuUrl)
              this.isActive = result[result.length-1].menuUrl
            }
          }
        }
      }else{
        this.$message.error(res.message)
      }
    },
    //点击二级以下菜单
    menuSelect(key, keyPath) {
      this.vtp.setSession('asideActive',key)
    },
    //点击一级菜单
    handleSelect (key,type) {
      let that = this
      sessionStorage.setItem('headActive',JSON.stringify(key))
      let str = this.menuData.find((li)=>{
        return li.id == key;//筛选出匹配数据
      });
      if(str){
        if(str.childPageMenus?.length>0 && str.buttonMenus.length==0){
          that.childMenu = str.childPageMenus
          that.hasChilMenu = true
          //在首页去二级页面的时候，默认打开一个有地址的页面
          let firstUrl = findFirstUrlInTree(str.childPageMenus)
          // console.log('firstUrl',firstUrl);
          
          if(firstUrl){
            that.isActive = firstUrl.menuUrl
            that.vtp.setSession('asideActive',firstUrl.menuUrl)
            if(type){
              that.$router.push({
                path:firstUrl.menuUrl
              })
            }
          }
        }else{
          that.hasChilMenu = false
          that.childMenu = []
          that.$router.push({
            path:str.menuUrl
          })
        }
      }else{
        that.hasChilMenu = false
        that.childMenu = []
      }
      setTimeout(() => {
        that.headActive = key
      }, 0);
      
    },
    
  },
  beforeDestroy(){
    websocketclose()
  }
}
</script>
<style lang="scss">
[v-cloak]{
  display: none;
}
#app {
  .asideMenu{
    background-color: #031e44;
    height: calc(100vh - 77px);
    overflow: auto;
    margin:10px 0 10px 3px;
    box-shadow: 0 5px 27px 5px rgba(7,130,255,.55) inset;
    .el-menu{
      border-right: none; 
      background: none;
    }
  }
  .appCont{
    position:relative;
  }
  .buttonMenu{
    position: absolute;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    overflow: hidden;
    color: #fff;
    // width: 60%;
    justify-content: center;
    .item{
      width: 108px;
      height: 30px;
      box-sizing: border-box;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
      font-size: 18px;
      margin: 0 5px;
      position: relative;
      background: url('./assets/images/ui/nav_r_2.png') no-repeat;
      .name{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.isLeft{
        background: url('./assets/images/ui/nav_l_2.png') no-repeat;
        &.leftNav{
          width: 131px;
          background: url('./assets/images/ui/nav_l_1.png') no-repeat !important;
          .name{
            width: 101px;
          }
        }
      }
      
      &.rightNav{
        width: 131px;
        background: url('./assets/images/ui/nav_r_1.png') no-repeat;
        display: inline-flex;
        justify-content: flex-end;
        .name{
          width: 101px;
        }
      }
      &.active{
        color: #00e4ff;
      }
      &.noShow{
        display: none;
      }
      &.isMg{
        margin-right: 290px;
      }
      &.isVbl{
        visibility: hidden;
      }
    }
  }
}
</style>
